<template>
  <div class="p-2 min-h-[400px]">
    <v-locale-provider :rtl="useIsRtl()">
      <div class="flex">
        <v-icon
          icon="mdi mdi-sort-reverse-variant rotate-180"
          :size="24"
          class="cursor-pointer"
        />
        <div class="mx-2">{{ t('sorting') }}</div>
        <div class="flex-auto"></div>
        <v-icon
          icon="mdi mdi-close"
          :size="24"
          class="cursor-pointer"
          @click="emit('close')"
        />
      </div>
      <div class="h-6 sm:h-12"></div>
      <div class="flex font-bold mb-2">
        <div class="text-nav_color">|</div>
        <div class="mx-2">{{ t('sorting_by') }}</div>
      </div>
      <div class="flex flex-wrap">
        <div
          v-for="order in orders"
          :key="`store-filter-order-${order.name}`"
          class="text-sm m-1 max-w-max cursor-pointer"
          @click="selectOrder(order)"
        >
          <div
            class="flex items-center w-max rounded-xl p-1"
            :class="{ 'active bg': order.name == currentOrderName }"
          >
            <v-icon
              icon=" mdi mdi-altimeter"
              :size="16"
              class="cursor-pointer"
            />

            <div class="p-1 text-xs sm:text-base">
              {{ order.name }}
            </div>
          </div>
        </div>
      </div>
      <div class="flex font-bold mt-5">
        <div class="text-nav_color">|</div>
        <div class="mx-2">{{ t('price') }}</div>
      </div>
      <div class="flex justify-center mx-auto mt-3">
        <div class="mx-3 flex">
          <div>{{ t('from') }}</div>
          <div class="font-medium mx-1">({{ valuesRangePrice[0] }})</div>
        </div>
        <div class="mx-3 flex">
          <div>{{ t('to') }}</div>
          <div class="font-medium mx-1">({{ valuesRangePrice[1] }})</div>
        </div>
      </div>
    </v-locale-provider>
    <div class="w-40 xs:w-48 sm:w-96 mt-3 mx-auto">
      <v-range-slider
        v-model="updateRangePrice"
        :min="0"
        :max="1000"
        :step="1"
        thumb-size:10
        :thumb-label="true"
        tick-size="2"
      >
      </v-range-slider>
    </div>
    <div class="flex justify-center mt-10 mb-5">
      <div class="w-20">
        <SelaSubmitButton
          color="bg-gray-300"
          text-color="text-gray-900 text-xs sm:text-base"
          type="button"
          :title="t('cancel')"
          @click="clearSort"
        />
      </div>

      <div class="w-2 xs:w-5"></div>

      <div class="w-20">
        <SelaSubmitButton
          color="bg-button_color"
          text-color="text-text_alt_color text-xs sm:text-base"
          type="button"
          :title="t('close')"
          @click="emit('close')"
        />
      </div>

      <div class="w-2 xs:w-5"></div>

      <div class="w-20">
        <SelaSubmitButton
          :title="t('apply')"
          text-color="text-white text-xs sm:text-base"
          type="button"
          @click="applyQuery()"
        />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
const valuesRangePrice = ref<number[]>([0, 1000])
const queryOrder = ref<{}>({})
const { t } = useI18n()

const updateRangePrice = computed({
  get() {
    return valuesRangePrice.value
  },

  set(value) {
    if (value[1] - value[0] >= 15) {
      valuesRangePrice.value = value
    }
  }
})

const route = useRoute()
const emit = defineEmits(['close'])
const orders = [
  {
    name: t('the_newest'),
    orderBy: 'created_at',
    orderType: 'desc',
    order_by_view_number: undefined,
    most_rated: undefined,
    order_by_sell_count: undefined,
    order_by_latest: undefined
  },
  {
    name: t('the_oldest'),
    orderBy: 'created_at',
    orderType: 'asc',
    order_by_view_number: undefined,
    most_rated: undefined,
    order_by_sell_count: undefined,
    order_by_latest: undefined
  },
  {
    name: t('most_watched'),
    order_by_view_number: '1',
    orderType: undefined,
    orderBy: undefined,
    most_rated: undefined,
    order_by_sell_count: undefined,
    order_by_latest: undefined
  },
  {
    name: t('most_rated'),
    most_rated: '1',
    orderType: undefined,
    order_by_view_number: undefined,
    orderBy: undefined,
    order_by_sell_count: undefined,
    order_by_latest: undefined
  },
  {
    name: t('most_seller'),
    order_by_sell_count: '1',
    orderType: undefined,
    orderBy: undefined,
    most_rated: undefined,
    order_by_view_number: undefined,
    order_by_latest: undefined
  },
  {
    name: t('the_cheapest')
  },
  {
    name: t('most_expensive')
  },
  {
    name: t('Recently_added'),
    order_by_sell_count: undefined,
    orderType: undefined,
    orderBy: undefined,
    most_rated: undefined,
    order_by_view_number: undefined,
    order_by_latest: 1
  }
]

const currentOrderName = ref('')

onMounted(() => {
  if (route.query.order_by_latest) {
    currentOrderName.value = t('Recently_added')
  }
  if (route.query.order_by_sell_count) {
    currentOrderName.value = t('the_cheapest')
  }
  if (route.query.most_rated) {
    currentOrderName.value = t('most_rated')
  }

  if (route.query.order_by_view_number) {
    currentOrderName.value = t('most_watched')
  }
  if (route.query.orderBy === 'created_at' && route.query.orderType === 'asc') {
    currentOrderName.value = t('the_oldest')
  }
  if (
    route.query.orderBy === 'created_at' &&
    route.query.orderType === 'desc'
  ) {
    currentOrderName.value = t('the_newest')
  }
  if (route.query.min_price !== undefined) {
    valuesRangePrice.value[0] = parseFloat(route.query.min_price!.toString())
  }
  if (route.query.max_price !== undefined) {
    valuesRangePrice.value[1] = parseFloat(route.query.max_price!.toString())
  }
})

function selectOrder(order: any) {
  if (currentOrderName.value !== order.name) {
    currentOrderName.value = order.name
    queryOrder.value = { ...queryOrder.value, ...order }
    queryOrder.value.name = undefined
  } else {
    currentOrderName.value = ''
    const clearQuery = {
      order_by_sell_count: undefined,
      orderType: undefined,
      orderBy: undefined,
      most_rated: undefined,
      order_by_view_number: undefined
    }
    queryOrder.value = {
      ...queryOrder.value,
      ...clearQuery
    }
  }
}
function applyQuery() {
  const queryTemp = {
    ...queryOrder.value
  }
  queryTemp.min_price =
    valuesRangePrice.value[0] === 0 ? undefined : valuesRangePrice.value[0]
  queryTemp.max_price =
    valuesRangePrice.value[1] === 1000 ? undefined : valuesRangePrice.value[1]
  useQueryNavigation(queryTemp, true)
  emit('close')
}

function clearSort() {
  useQueryNavigation(
    {
      orderBy: undefined,
      orderType: undefined,
      order_by_view_number: undefined,
      most_rated: undefined,
      order_by_sell_count: undefined,
      min_price: undefined,
      max_price: undefined
    },
    true
  )

  emit('close', true)
}
</script>

<style>
.active {
  @apply text-nav_color  !important;
}
.active.bg {
  @apply bg-nav_color text-text_alt_color bg-opacity-20 !important;
}
</style>
