<template>
  <v-locale-provider :rtl="useIsRtl()">
    <div class="p-2 min-h-[400px] flex flex-col h-full w-full">
      <div>
        <div class="flex">
          <v-icon
            icon="mdi mdi-filter-outline"
            :size="24"
            class="cursor-pointer"
          />
          <div>{{ t('filtering') }}</div>
          <div class="flex-auto"></div>
          <v-icon
            icon="mdi mdi-close"
            :size="24"
            class="cursor-pointer"
            @click="emit('close')"
          />
        </div>
      </div>
      <div class="flex-1 overflow-y-auto w-full">
        <div class="h-6 sm:h-12"></div>
        <div class="flex font-bold mb-2">
          <div class="text-nav_color">|</div>
          <div class="mx-2">{{ t('Type') }}</div>
        </div>
        <div class="flex flex-wrap mt-3 bg-bg_alt_color rounded-lg mx-2 p-1">
          <button
            v-for="order in orders"
            :key="`store-filter-order-${order.name}`"
            class="text-sm mx-1"
            @click="selectOrder(order)"
          >
            <div
              class="px-3 py-1 rounded-xl"
              :class="{ 'active bg': order.name == currentOrderName }"
            >
              {{ order.name }}
            </div>
          </button>
        </div>
        <div class="h-6 sm:h-12"></div>
        <div class="flex flex-wrap">
          <div class="flex font-bold">
            <div class="text-nav_color">|</div>
            <div class="mx-2">{{ t('name') }}</div>
          </div>
          <div class="w-12"></div>
          <StoresFilterSearch
            class="mb-3"
            with-out-query-navigation
            @go-material-search="queryOrder.name = $event.trim()"
          />
        </div>
        <StoresFilterDynamicPropertiesFilter
          ref="dynamicRef"
          :material-definitions="materialDefinitions"
        />
        <div v-if="categories?.length">
          <div class="h-6 sm:h-12"></div>
          <div class="flex font-bold">
            <div class="text-nav_color">|</div>
            <div class="mx-2">{{ t('the_category') }}</div>
          </div>
          <StoresFilterCategoriesParentsFilterMultiLevel
            :categories="categories"
            :with-out-query-navigation="true"
            @go-catgory-id="queryOrder.category_id = $event"
          >
            <template
              #categoriesParent="{
                goToCategory,
                isActiveCategory,
                categoriesCategoryStyle
              }"
            >
              <RikazHorizontalDrag>
                <div class="flex items-start max-w-max mx-auto">
                  <button
                    v-for="(item, index) in categoriesCategoryStyle"
                    :key="`store-filter-order-${item.name}`"
                    class="text-xs mx-1 mt-5"
                    @click="goToCategory(index)"
                  >
                    <div class="w-12 xs:w-16 sm:w-20 md:28 lg:w-32 mx-auto">
                      <NuxtImg
                        style="border-radius: 20px"
                        provider="cacheIpx"
                        preset="modified"
                        :src="item.image"
                        :alt="item.name"
                        width="128"
                        height="128"
                        class="mx-auto aspect-square object-cover border"
                      />
                      <div
                        class="h-1 w-8/12 mx-auto"
                        :class="{
                          'border-b-2  border-nav_color ':
                            isActiveCategory(index)
                        }"
                      ></div>
                    </div>

                    <div
                      class="py-1 pb-1"
                      :class="[
                        {
                          'text-nav_color': isActiveCategory(index) && !isDomain
                        },
                        {
                          'text-text_alt_color':
                            isActiveCategory(index) && isDomain
                        }
                      ]"
                    >
                      <ClientOnly>{{ item.name }}</ClientOnly>
                    </div>
                  </button>
                </div>
              </RikazHorizontalDrag>
            </template>
          </StoresFilterCategoriesParentsFilterMultiLevel>
        </div>
      </div>
      <div>
        <div class="flex justify-center mt-10">
          <div class="w-20">
            <SelaSubmitButton
              :title="t('apply')"
              text-color="text-white text-xs sm:text-base"
              type="button"
              @click="applyQuery()"
            />
          </div>

          <div class="w-2 xs:w-5"></div>

          <div class="w-20">
            <SelaSubmitButton
              color="bg-button_color"
              text-color="text-text_alt_color text-xs sm:text-base"
              type="button"
              :title="t('close')"
              @click="emit('close')"
            />
          </div>

          <div class="w-2 xs:w-5"></div>

          <div class="w-20">
            <SelaSubmitButton
              color="bg-gray-300"
              text-color="text-gray-900 text-xs sm:text-base"
              type="button"
              :title="t('cancel')"
              @click="clearFilter"
            />
          </div>
        </div>
      </div>
      <div class="h-6"></div>
    </div>
  </v-locale-provider>
</template>

<script setup lang="ts">
import type { Category } from '~~/composables/useMenuModel'
const dynamicRef = ref<HTMLElement | null>(null)
const props = withDefaults(
  defineProps<{
    categories: Category[] | null
    queryParams?: object
    storeTypeId?: number | string
  }>(),
  {
    storeTypeId: undefined,
    queryParams: () => ({})
  }
)

const isDomain = useIsDomain()
const { storeTypes } = useStoreTypes()
const { materialDefinitions: materialDefinitionsDomain } = useMaterialDefinitionsStore()
const materialDefinitions = computed(() =>
  isDomain
    ? materialDefinitionsDomain.value
    : storeTypes.value?.find(
      (el) => el.id.toString() === props.storeTypeId?.toString()
    )?.material_definitions
)
const queryOrder = ref<{}>({}) as any

const { t } = useI18n()
const route = useRoute()
const emit = defineEmits(['close'])
const orders = [
  {
    name: t('all'),
    is_featured: undefined,
    offersOnly: undefined
  },
  {
    name: t('featured'),
    is_featured: true,
    offersOnly: undefined
  },
  {
    name: t('offers'),
    offersOnly: true,
    is_featured: undefined
  }
]

const currentOrderName = ref('')

function selectOrder(order: any) {
  queryOrder.value = { ...order }
  queryOrder.value.name = undefined
  if (
    currentOrderName.value === order.name &&
    currentOrderName.value === t('all')
  ) {
    return ''
  } else {
    currentOrderName.value =
      currentOrderName.value !== order.name ? order.name : t('all')
    if (currentOrderName.value === t('all')) {
      queryOrder.value = {
        ...queryOrder.value,
        is_featured: undefined,
        offersOnly: undefined
      }
    }
  }
}

function applyQuery() {
  queryOrder.value.name = queryOrder.value.name?.trim() || undefined
  const queryDynamic = dynamicRef.value.goQueryDynamic()
  const queryTemp = { ...queryOrder.value, ...queryDynamic }

  useQueryNavigation(queryTemp, true)
  emit('close', true)
}

function clearFilter() {
  useQueryNavigation(emptyQuery.value, true)

  emit('close', true)
}
const emptyQuery = computed(() => {
  const currentQuery = { ...route.query } as any
  const keysToDelete = [
    'category_id',
    'name',
    'is_featured',
    'offersOnly',
    'definition_id'
  ]
  Object.keys(currentQuery).forEach((key) => {
    if (keysToDelete.includes(key) || key.includes('DYNAMIC_FIELD')) {
      currentQuery[key] = undefined
    }
  })
  return currentQuery
})

onMounted(() => {
  queryOrder.value.name = route.query.name
  if (route.query.is_featured) {
    currentOrderName.value = t('featured')
  }
  if (route.query.offersOnly) {
    currentOrderName.value = t('offers')
  }
  if (!route.query.is_featured && !route.query.offersOnly) {
    currentOrderName.value = t('all')
  }
})
</script>
